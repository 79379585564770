import * as available from './available';
import { ICONPLUS } from './iconPlus';

const { library } = require('@fortawesome/fontawesome-svg-core');

/**
 * Adds the icons to the library of available icons when using <Icon />
 * component.
 */
library.add(...(Object.values(available) as any));

export type IconPlusName = keyof typeof ICONPLUS;

export const allIconsPlus = ICONPLUS as Record<IconPlusName, any>;
