import AppCard from './iconplus/plus_appcard.svg';
import AutomaticInvoice from './iconplus/plus_automaticinvoice.svg';
import BankConnect from './iconplus/plus_bankconnect.svg';
import Barchart from './iconplus/plus_barchart.svg';
import Calendar from './iconplus/plus_calendar.svg';
import CardReceipt from './iconplus/plus_cardreceipt.svg';
import ChainConnect from './iconplus/plus_chainconnect.svg';
import CheckList from './iconplus/plus_checklist.svg';
import CheckMark from './iconplus/plus_checkmark.svg';
import CloudWifi from './iconplus/plus_cloudwifi.svg';
import CogWheel from './iconplus/plus_cogwheel.svg';
import CollaboratePuzzle from './iconplus/plus_collaboratepuzzle.svg';
import CompanyGroup from './iconplus/plus_companygroup.svg';
import ComputorMobile from './iconplus/plus_computormobile.svg';
import DigitalMailbox from './iconplus/plus_digitalmailbox.svg';
import Empty from './iconplus/plus_empty.svg';
import Envelope from './iconplus/plus_envelope.svg';
import Fillbox from './iconplus/plus_fillbox.svg';
import FistBump from './iconplus/plus_fistbump.svg';
import FortnoxApp from './iconplus/plus_fortnoxapp.svg';
import HammerBroom from './iconplus/plus_hammerbroom.svg';
import HammerSuitcase from './iconplus/plus_hammersuitcase.svg';
import Handshake from './iconplus/plus_handshake.svg';
import IceCreamFloor from './iconplus/plus_icecreamfloor.svg';
import InvoiceDigital from './iconplus/plus_invoicedigital.svg';
import InvoiceHand from './iconplus/plus_invoicehand.svg';
import IslandCheck from './iconplus/plus_islandcheck.svg';
import LightBulb from './iconplus/plus_lightbulb.svg';
import Money from './iconplus/plus_money.svg';
import Network from './iconplus/plus_network.svg';
import PaperPlane from './iconplus/plus_paperplane.svg';
import PersonAgreement from './iconplus/plus_personagreement.svg';
import Plant from './iconplus/plus_plant.svg';
import Scale from './iconplus/plus_scale.svg';
import SecurityCloud from './iconplus/plus_securitycloud.svg';
import SmallBigHouse from './iconplus/plus_smallbighouse.svg';
import StackedBoxes from './iconplus/plus_stackedboxes.svg';
import Star from './iconplus/plus_star.svg';
import Support from './iconplus/plus_support.svg';
import UploadFile from './iconplus/plus_uploadfile.svg';
import UserCheck from './iconplus/plus_usercheck.svg';
import Users from './iconplus/plus_users.svg';
import ViewDetail from './iconplus/plus_viewdetail.svg';
import WandForm from './iconplus/plus_wandform.svg';

export const ICONPLUS = {
	plus_appcard: AppCard,
	plus_automaticinvoice: AutomaticInvoice,
	plus_bankconnect: BankConnect,
	plus_barchart: Barchart,
	plus_calendar: Calendar,
	plus_cardreceipt: CardReceipt,
	plus_chainconnect: ChainConnect,
	plus_checklist: CheckList,
	plus_checkmark: CheckMark,
	plus_cloudwifi: CloudWifi,
	plus_cogwheel: CogWheel,
	plus_collaboratepuzzle: CollaboratePuzzle,
	plus_companygroup: CompanyGroup,
	plus_computormobile: ComputorMobile,
	plus_digitalmailbox: DigitalMailbox,
	plus_empty: Empty,
	plus_envelope: Envelope,
	plus_fillbox: Fillbox,
	plus_fistbump: FistBump,
	plus_fortnoxapp: FortnoxApp,
	plus_hammerbroom: HammerBroom,
	plus_hammersuitcase: HammerSuitcase,
	plus_handshake: Handshake,
	plus_icecreamfloor: IceCreamFloor,
	plus_invoicedigital: InvoiceDigital,
	plus_invoicehand: InvoiceHand,
	plus_islandcheck: IslandCheck,
	plus_lightbulb: LightBulb,
	plus_money: Money,
	plus_network: Network,
	plus_paperplane: PaperPlane,
	plus_personagreement: PersonAgreement,
	plus_plant: Plant,
	plus_scale: Scale,
	plus_securitycloud: SecurityCloud,
	plus_smallbighouse: SmallBigHouse,
	plus_stackedboxes: StackedBoxes,
	plus_star: Star,
	plus_support: Support,
	plus_uploadfile: UploadFile,
	plus_usercheck: UserCheck,
	plus_users: Users,
	plus_viewdetail: ViewDetail,
	plus_wandform: WandForm,
};
